// 该页面未使用    2023-03-21
<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/Manager' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>年级管理</el-breadcrumb-item>
            <el-breadcrumb-item>等级列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <el-row :gutter="30">
                <el-col :span="8">
                    <!--搜索-->
                    <el-input placeholder="请输入等级名称" v-model="queryInfo.name" clearable @clear="GetLeveList"> 
                        <el-button slot="append" icon="el-icon-search" @click="GetLeveList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addLevelDialogVisible = true">添加等级</el-button>
                </el-col>
            </el-row>
            <el-table :data="levelList" stripe>
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="描述" prop="description"></el-table-column> 
                <el-table-column label="操作" prop="option" width="300">
                    <template slot-scope="scope"> 
                        <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                            <el-button type="primary" icon="el-icon-edit" @click="ShowModifyLevelDialog(scope.row)">编辑</el-button>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <el-button type="danger" icon="el-icon-delete" @click="DeleteLevelDialog(scope.row)">删除</el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.start"
                :page-sizes="[1,2,5,10]"
                :page-size="queryInfo.count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="levelstotal">
            </el-pagination>
        </el-card>
        <el-dialog title="添加等级" :visible.sync="addLevelDialogVisible" width="30%"  @close="AddLevelDialogClosed" :close-on-click-modal="false">
            <el-form :model="addLevelForm" :rules="addLevelFormRules" ref="addLevelFormRulesRef"  label-width="100px">
                <el-form-item label="名称：" prop="Name">
                    <el-input v-model="addLevelForm.Name"></el-input>
                </el-form-item>
                <el-form-item label="描述：" prop="Description">
                    <el-input v-model="addLevelForm.Description"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addLevelDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="AddLevel">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="编辑等级" :visible.sync="editLevelDialogVisible" width="30%" @close="EditLevelDialogClosed" :close-on-click-modal="false">
            <el-form :model="editLevelForm" :rules="editLevelFormRules" ref="editLevelFormRulesRef"  label-width="100px">
                <el-form-item label="名称：" prop="name">
                    <el-input v-model="editLevelForm.name"></el-input>
                </el-form-item>
                <el-form-item label="描述：" prop="description">
                    <el-input v-model="editLevelForm.description"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editLevelDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="ModifyLevel">确 定</el-button>
            </span>
        </el-dialog>
    </div>    
</template>

<script>
export default {
    data(){
        return{
            levelList:[],
            queryInfo:{
                name:'',
                start:1,
                count:10
            },
            levelstotal:0,
            addLevelDialogVisible:false,
            addLevelForm:{
                Name:'',
                Description:''
            },
            addLevelFormRules:{
                Name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                Description: [
                    { required: true, message: '请输入描述', trigger: 'blur' }
                ]
            },
            editLevelDialogVisible:false,
            editLevelForm:{
                id:0,
                name:'',
                description:''
            },
            editLevelFormRules:{
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                description: [
                    { required: true, message: '请输入描述', trigger: 'blur' }
                ]
            }
        }
    },
    created(){
        this.GetLeveList()
    },
    methods:{
        async GetLeveList(){
            const {data:res} = await this.$http.get('LevelInfo/v1/LevelInfoQuery',{params: this.queryInfo})
            if(res.code !== 1){
                return this.$message.error('获取等级列表失败');
            }
            this.levelList = res.data.levelInfos
            this.levelstotal = res.data.total
        },
        handleSizeChange(count){
            this.queryInfo.count = count
            this.GetLeveList()
        },
        handleCurrentChange(start){
            this.queryInfo.start = start
            this.GetLeveList()
        },
        AddLevelDialogClosed(){
            this.$refs.addLevelFormRulesRef.resetFields()
        },
        EditLevelDialogClosed(){
            this.$refs.editLevelFormRulesRef.resetFields()
        },
        ShowModifyLevelDialog(level){
            let that = this
            that.editLevelForm.id = level.id
            that.editLevelForm.name = level.name
            that.editLevelForm.description = level.description
            that.editLevelDialogVisible = true
        },
        async DeleteLevelDialog(level){
            let that = this
            const confirmResult = await that.$confirm('此操作将永远删除该等级, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return that.$message.info('已取消删除')
            }
        
            await that.$http.delete('LevelInfo/v1/LevelInfo',{params: {id : level.id }}).
            then(function (response) {
                if(response.data.code != 1)
                {
                    that.$message.info('删除失败')
                }
                else
                {
                    that.$message.success('删除成功')
                }
            }).catch(function (error) {
                return that.$message.info(error)
            });
            that.GetLeveList()
        },
        async AddLevel(){
            this.$refs.addLevelFormRulesRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            } 
            await this.$http.post('LevelInfo/v1/LevelInfo',this.editLevelForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    return this.$message.error('添加等级失败')
                }
            }).catch(function (error) {
                return this.$message.error(error)
            });
            this.$message.success('添加等级成功')
            this.GetLeveList()
            this.validret = false
            this.addLevelDialogVisible = false
        },
        async ModifyLevel(){
            this.$refs.editLevelFormRulesRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            } 
            await this.$http.put('LevelInfo/v1/LevelInfo',this.editLevelForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    return this.$message.error('编辑等级失败')
                }
            }).catch(function (error) {
                return this.$message.error(error)
            });
            this.$message.success('编辑等级成功')
            this.GetLeveList()
            this.validret = false
            this.editLevelDialogVisible = false
        }
    }
}
</script>